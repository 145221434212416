import * as React from "react";
import type { HeadFC } from "gatsby";
import "../styles/reset.css";
import "../styles/index.scss";
import Layout from "../components/Layout";
import Slab from "../components/Slab";
import Button from "../components/Button";
const StylesPage = () => {
  return (
    <Layout>
      <Slab>
        <h1>Button</h1>
        <div className="flex">
          <div className="w-1/2 p-10 bg-lime">
            <Button to="https://aidsvu.org">Visit AIDSVu.org</Button>
          </div>
          <div className="w-1/2 p-10 bg-red">
            <Button to="https://aidsvu.org" external>
              Visit AIDSVu.org
            </Button>
          </div>
        </div>
      </Slab>
      <Slab>
        <h1>Typography</h1>
        <div>
          {["h1", "h2"].map((Tag) => (
            <Tag>{Tag}. Lorem Ipsum</Tag>
          ))}
          <h2 className="text-header-2-alt">h2.alt Lorem Ipsum</h2>
        </div>
        <div>
          {[
            "text-body",
            "text-body-small",
            "text-reference",
            "text-footnote",
          ].map((klass) => (
            <p className={klass}>
              The quick brown fox jumped over the lazy dog
            </p>
          ))}
        </div>
      </Slab>
    </Layout>
  );
};

export default StylesPage;

export const Head: HeadFC = () => <title>Style Guide</title>;
